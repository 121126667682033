.s-resume {
  background-color: white;
  padding-top: calc(6 * var(--space));
  padding-bottom: var(--vspace-4);
}

.s-resume__section {
  border-bottom: 1px solid var(--color-border);
  padding-bottom: var(--vspace-0_75);
  margin-bottom: var(--vspace-2_5);
}

.s-resume__section:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

/* ------------------------------------------------------------------- 
 * ## resume block
 * ------------------------------------------------------------------- */
.resume-block {
  margin-bottom: var(--vspace-1_75);
}

.resume-block h4 {
  line-height: 1.08;
  margin-top: 0;
  margin-bottom: var(--vspace-0_25);
}

.resume-block__header-meta {
  font-family: var(--font-2);
  font-size: calc(var(--text-size) * 1.1111);
  font-style: italic;
  margin-top: -0.4rem;
  margin-bottom: var(--vspace-0_75);
}

.resume-block__header-meta span:first-child {
  margin-right: 0.6rem;
}

.resume-block__header-date {
  display: inline-block;
  font-family: var(--font-1);
  font-size: calc(var(--text-size) * 0.8889);
  font-size: var(--text-sm);
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  color: var(--color-text-light);
}

.resume-block__header-date::before {
  content: "\2022";
  color: var(--color-text);
  margin-right: 0.2rem;
}

/* ------------------------------------------------------------------- 
 * responsive:
 * resume
 * ------------------------------------------------------------------- */
@media screen and (max-width: 800px) {
  .s-resume {
    padding-top: var(--vspace-4);
  }
  .s-resume__section .column:first-child {
    text-align: center;
  }
}

/* ------------------------------------------------------------------- 
 * ## skillbars
 * ------------------------------------------------------------------- */
.skill-bars-fat {
  list-style: none;
  margin: var(--vspace-2) 0 var(--vspace-1);
}

.skill-bars-fat li {
  height: 5.2rem;
  background: transparent;
  margin-bottom: 5.2rem;
  padding: 0;
  position: relative;
}

.skill-bars-fat li strong {
  font-family: var(--font-1);
  font-weight: 600;
  color: var(--color-text-dark);
  text-transform: capitalize;
  letter-spacing: 0.2em;
  font-size: calc(var(--text-size) * 0.7778);
  line-height: 2rem;
  position: absolute;
  top: -3.2rem;
  left: 0;
}

.skill-bars-fat li .progress {
  background: var(--color-gray-3);
  position: relative;
  height: 100%;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
}

.skill-bars-fat li .progress::before {
  content: "";
  display: block;
  height: 100%;
  background-color: var(--color-gray-18);
}

.skill-bars-fat li .percent5::before {
  width: 5%;
}

.skill-bars-fat li .percent10::before {
  width: 10%;
}

.skill-bars-fat li .percent15::before {
  width: 15%;
}

.skill-bars-fat li .percent20::before {
  width: 20%;
}

.skill-bars-fat li .percent25::before {
  width: 25%;
}

.skill-bars-fat li .percent30::before {
  width: 30%;
}

.skill-bars-fat li .percent35::before {
  width: 35%;
}

.skill-bars-fat li .percent40::before {
  width: 40%;
}

.skill-bars-fat li .percent45::before {
  width: 45%;
}

.skill-bars-fat li .percent50::before {
  width: 50%;
}

.skill-bars-fat li .percent55::before {
  width: 55%;
}

.skill-bars-fat li .percent60::before {
  width: 60%;
}

.skill-bars-fat li .percent65::before {
  width: 65%;
}

.skill-bars-fat li .percent70::before {
  width: 70%;
}

.skill-bars-fat li .percent75::before {
  width: 75%;
}

.skill-bars-fat li .percent80::before {
  width: 80%;
}

.skill-bars-fat li .percent85::before {
  width: 85%;
}

.skill-bars-fat li .percent90::before {
  width: 90%;
}

.skill-bars-fat li .percent95::before {
  width: 95%;
}

.skill-bars-fat li .percent100::before {
  width: 100%;
}

@media screen and (max-width: 868px) {
  .skill-bars-fat li strong {
    top: -3.8rem;
  }
}
